/* @import url("./Style/5ad142d0e49ca868b1530c30a47c625f.woff");*/
@font-face {
  font-family: 'SF UI Text';
  src: local('SF UI Text'), url(./5ad142d0e49ca868b1530c30a47c625f.woff) format('woff');
  /* other formats include: 'woff2', 'truetype, 'opentype', 'embedded-opentype', and 'svg' */
}

* {
  font-family: "SF UI Text";
  --body-padding: 15px;
  --body-header-height: 70px;
  --header-height: 70px;
  --sidebar-width: 220px;
}

body {
  margin: 0;
  padding: 0;
}

.App {
  width: 100vw;
  height: 100vh;
  background-color: #E5E5E5;
  display: flex;
  flex-direction: row;
}

.mainsection {
  display: flex;
  width: 100%;
  height: 100vh;
}