.page {
    flex: 1;
    background-color: #E5E5E5;
    display: flex;
    flex-direction: row;
    overflow: scroll;
}

.main_D {
    display: flex;
    width: 100%;
    flex-direction: column;

}

.BodySection {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.DataTableHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    height: var(--body-header-height);
    padding-left: 5%;
    padding-right: 5%;
}

.DashboardDropdownFilters {
    width: 30%;
    min-height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.DashboardGraphContainer {
    height: 100%;
    width: 100%;
    position: relative;
}

.Transactions_Dropdown {
    width: 70%;
    height: 50%;
}

.comptitile {
    font-weight: 600;
}

.user_numbers_data_section {
    width: 100%;
    height: var(--body-header-height);
    align-self: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
}

.total_user_numbers_div {
    flex: .25;
    background-image: linear-gradient(274.73deg, #64C4B6 -11.19%, #0B3758 96.18%);
    border-radius: 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    align-content: center;
    color: #fff;
}

.total_revenue_numbers_div {
    flex: .25;
    background-image: linear-gradient(15deg, #64C4B6 -11.19%, #0B3758 96.18%);
    border-radius: 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    align-content: center;
    color: #fff;
}

.user_numbers_analytics_div {
    flex: .75;
    background-color: #fff;
    border-radius: 12px;
    display: flex;
    flex-direction: row;
}

.user_numbers_analytics {
    width: 25%;
    height: 80%;
    align-self: center;
    border-right: 1.5px solid #F0F0F0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.user_numbers_data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 55%
}

.percentage_number {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.user_numbers_graph {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30%
}

.waiting_approval_users {
    width: 25%;
    height: 80%;
    align-self: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #FF4C4C;
}

.users_transactions_div {
    width: 100%;
    height: 420px;
    align-self: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
}

.users_graph_div {
    position: relative;
    height: 100%;
    width: 65%;
    background-color: #FFF;
    border-radius: 12px;
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.transactions_charts_div {
    height: 100%;
    width: 35%;
    background-color: #fff;
    border-radius: 12px;
    align-self: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.transactions_charts_title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 15%;
    width: 96%;
    align-content: center;
    align-self: center;
}

.transactions_charts_data_div {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    height: 15%;
    width: 96%;
    background-color: #0B3758;
    align-self: center;
    border-bottom: #64c4b6 solid 9px;
    font-size: small;
    font-weight: bold;
    align-items: center;
    border-radius: 5px;

}

.transactions_charts_data_title {
    color: #FFF;
    margin-left: 10px;
}

.transactions_charts_data_nbrs {
    color: #FFF;
    margin-left: auto;
    margin-right: 15%;
}

.transactions_type_div {
    width: 100%;
    height: fit-content;
    align-self: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
}

.transactions_piecharts_div {
    height: 240px;
    width: 25%;
    background-color: #FFF;
    border-radius: 12px;
    align-self: center;
    display: flex;
    flex-direction: column;
}

.transactions_piecharts_title {
    height: 20%;
    width: 90%;
    align-self: center;
    border-bottom: 1px solid #C4C4C4;
    font-size: small;
    font-weight: 700;
    justify-content: flex-end;
    display: flex;
    flex-direction: column;
}

.transactions_piecharts {
    height: 70%;
    align-self: center;

}