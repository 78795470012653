.Header {
    width: 100%;
    height: var(--header-height);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
}

.SearchBar {
    width: 75%;
    height: 60%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-left: 5%;
}

.Search {
    height: 95%;
    width: 75%;
    border-radius: 5px;
    background-color: #FFF;
}

.Admin {
    width: 23%;
    height: 80%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-right: 2%;
}

.Admin_Name {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: right;
    font-size: small;
    margin-right: 5%;
}

.Role {
    font-size: smaller;
}