.main {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.BodySection {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
}

.comptitile {
    font-weight: 600;
}

.DataTable {
    border-radius: 10px;
    display: flex;
    width: 100%;
    height: 100%;
    background-color: #fff;
    align-self: center;
}

.DataTableHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    height: var(--body-header-height);
    padding-left: 5%;
    padding-right: 5%;
}

.textField {
    width: 100%;
}

.dialogContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    width: 500px;
    min-height: max-content;
    margin-top: 5px;
    gap: 25px;
}

.buttonStyle {
    text-decoration: none;
    background-color: #0b3758;
}
.headerCalc{
    width: 100%;
    display: flex;
    margin: 0.3em auto;
    justify-content: start;
    color: #fff;
    font-size: small;

}

.headerCalc p:nth-child(1) {
    background-color: #0b3758;
    margin: 0 0.5em;
    padding: 0.2em 0.5em;
}

.headerCalc p:nth-child(2) {
    background-color: #ffa700; /* Change this to the color you want */
    margin: 0 0.5em;
    padding: 0.2em 0.5em;
}

.headerCalc p:nth-child(3) {
    background-color: #60100B; /* Change this to the color you want */
    margin: 0 0.5em;
    padding: 0.2em 0.5em;
}