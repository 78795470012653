.loginbox {
  width: 130%;
  padding: 30px 60px 30px 60px;
  border: 1px solid #0b3758;
  background-color: white;
  /* box-shadow: 10px 10px 5px #64c4b6; */
  border-radius: 10px;
  -webkit-box-shadow: 0 0 10px #64c4b6;
  box-shadow: 0 0 10px #64c4b6;
}

.AppLogo_View {
  height: 15%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.AppLogo {
  width: 100%;
  height: 75%;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loadspinner {
  justify-content: center;
  border: 8px solid #64C4B6;
  border-top: 8px solid #0b3758;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1.5s linear infinite;
}

.loadingsection {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 100000;
  position: absolute;
}
.fogetButton{
  padding: 0;
  margin: 0;
  color:#034e88;
  font-size: small;
}
.forgetDev{
display: flex;
justify-content: end;
}