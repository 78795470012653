.main {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.BodySection {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
}

.comptitile {
    font-weight: 600;
}

.DataTable {
    border-radius: 10px;
    display: flex;
    width: 100%;
    height: 100%;
    background-color: #fff;
    align-self: center;
    
}

.DataTableHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    height: 100%;
    padding-left: 5%;
    padding-right: 5%;
}

.textField {
    width: 100%;
}

.dialogContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    width: 500px;
    min-height: max-content;
    margin-top: 5px;
    gap: 25px;
}

.buttonStyle {
    text-decoration: none;
    background-color: #0b3758;
}
.headerOfInfo{
    width: 90%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: end;
    color: rgb(91, 90, 90);
    font-size: small;
}
.headerTitle{
    width: 90%;
    margin: auto;
    padding: 0;
}
.dataEmpty{
  text-align: center;
}
.colored_box {
    width: auto; 
    height: auto; 
    background-color: lightblue; 
    border: 1px solid black; 
    margin-bottom: 10px;
    padding-left: 10px;
}

.content_box {
    padding: 10px; 
}

.filters_box{
width: 90%;
margin:  0.5rem auto;
display: flex;
justify-content:space-between;
align-items: center;
padding: 1rem 0;

}
.date_filters{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 30%;
    align-items: center;
    
}