.usercard {
    display: flex;
    height: fit-content;
    width: 240px;
    flex-direction: column;
    justify-content: left;
    padding: 15px;
    position: absolute;
    z-index: 100000;
    background-color: rgb(255, 255, 255);
    border-radius: 12px;
    border: #000 0.5px solid;
    box-shadow: 5px 5px #88888826;
  }
.UserDetails_Data_Row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
.UserDetails_Data_Title {
    font-size: small;
    height: 25px;
    color: #808080;
    }  
.UserDetails_Data_Result {
    font-size: small;
    height: 25px;
    color: #000;
    margin-right: 5%;
  }