.SideBarLogo {
  width: 100%;
  height: 75px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.Sidebar {
  height: 100%;
  width: var(--sidebar-width);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.SidebarListContainer {
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.SidebarList {
  height: 100%;
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.row {
  width: 100%;
  height: 30px;
  list-style-type: none;
  display: flex;
  flex-direction: row;
  color: #000;
  justify-content: center;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;
}

.row:hover {
  cursor: pointer;
}

.SidebarList #Active {
  background-image: linear-gradient(270.04deg, #64C4B6 -18.93%, #0B3758 99.97%);
  border-radius: 12px;
  margin-right: 5%;
  margin-left: 5%;
  width: 90%;
  color: #FFFFFF;
}

.icon {
  flex: 30%;
  display: grid;
  place-items: center;
}

.title {
  flex: 70%;
}

.AppLogo_View {
  height: 12%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.AppLogo {
  width: 100%;
  height: 75%;
}

.footerContainer {
  height: 10%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.LogOut {
  padding: 0;
  width: 100%;
  list-style-type: none;
  display: flex;
  flex-direction: row;
  color: #000;
  justify-content: center;
  align-items: flex-end;
}

.LogOut:hover {
  cursor: pointer;
}

.LogOut_Icon {
  flex: 30%;
  display: grid;
  place-items: center;
}

.LogOut_Text {
  flex: 70%;
}