.maind {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.BodySectiond {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
}

.DataTableHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  height: var(--body-header-height);
  padding-left: 5%;
  padding-right: 5%;
}

.ButtonsSections {
  width: 50%;
  height: 50%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.DataSection {
  display: flex;
  width: 100%;
  height: 100%;
  gap: 10px;
}

.UserData {
  width: 30%;
  height: fit-content;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 5px 5px 5px 5px #a1a1a150;
}

.UserWallet {
  width: 70%;
  height: auto;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 5px 5px 5px 5px #a1a1a150;
}

.User {
  width: 100%;
  height: 25%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#avatar {
  width: 90px;
  height: 90px;
}

#ID_number {
  color: rgb(80, 80, 80);
  font-size: small;
  text-align: center;
  margin-top: 10px;
}

.UserDetails {
  width: 100%;
  height: auto;
}

.Details_title {
  display: flex;
  font-size: medium;
  width: 90%;
  margin-left: 5%;
  border-bottom: 1px solid #80808049;
}

.UserDetails_Data {
  display: flex;
  height: auto;
  flex-direction: column;
  justify-content: left;
  margin-left: 5%;
  margin-top: 5%;
}

.UserDetails_Data_Title {
  font-size: small;
  height: 25px;
  color: #808080;
}

.UserDetails_Data_Result {
  font-size: small;
  height: 25px;
  color: #000;
  margin-right: 5%;
}

.UserDetails_Data_Row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.currencies_div {
  width:auto;
  height:auto;
  background-color: #0b3758;
  position: absolute;
  margin-left: 50%;
  margin-top: 120px;
  z-index: 100;
  border-radius: 8px;
  padding: 15px;
  color: #fff;
  box-shadow: 12px 8px 63px -15px #0b3758;
  -webkit-box-shadow: 12px 8px 63px -15px #0b3758;
  -moz-box-shadow: 12px 8px 63px -15px #0b3758;
}

.MasterWallet {
  width: 28%;
  height: 95%;
  margin-left: 3%;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 5px 5px 5px 5px #a1a1a150;
}

.MasterWallet_info {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 30%;
  border-bottom: #0b3758 1px solid;

  padding-left: 10px;
  padding-right: 10px;
}

.MasterWallet_send {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 65%;
  padding-top: 5%;
  padding-left: 10px;
  padding-right: 10px;
}

#wallet_address:hover {
  cursor: pointer;
  color: #0b3758;
}

.dialogContainer {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 15px 0 15px 0;
  width: 350px
}
.amountContainer {
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: space-between;
}