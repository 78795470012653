.main {
 display: flex;
 width: 100%;
 flex-direction: column;
}

.bodyContainer {
 display: flex;
 flex-direction: row;
 gap: 10px;
 width: 100%;
 height: 100%;
}

.BodySection {
 display: flex;
 width: 100%;
 height: 100%;
 flex-direction: column;
}

.DataTableHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    height: 15%;
    padding-left: 5%;
    padding-right: 5%;
}

.DropdownFilters {
 width: 30%;
 height: 100%;
 display: flex;
 flex-direction: row;
 justify-content: space-around;
 align-items: center;
}

.Dropdown {
 width: 30%;
 height: 33%;
 margin-right: 2%;
 border-radius: 8px;

}

.DataTable {
 border-radius: 10px;
 display: flex;
 width: 100%;
 height: 100%;
 background-color: #fff;
 align-self: center;
}

.creadetby_div {
 display: flex;
 flex-direction: column;
 justify-content: center;
}

.name {
 font-size: medium;
 font-weight: 700
}

.date {
 font-size: small;
 font-weight: 300;
 color: grey;
}

.priority_high {
 background-color: red;
 color: #fff;
 padding-inline: 5px;
 border-radius: 25px;
}

.priority_medium {
 background-color: orange;
 color: #fff;
 padding-inline: 5px;
 border-radius: 25px;
}

.priority_low {
 background-color: blue;
 color: #fff;
 padding-inline: 5px;
 border-radius: 25px;
}

.priority_not {
 background-color: grey;
 color: #fff;
 padding-inline: 5px;
 border-radius: 25px;
}

.status_closed {
 background-color: grey;
 color: #fff;
 padding-inline: 5px;
 border-radius: 25px;
}

.status_open {
 background-color: green;
 color: #fff;
 padding-inline: 5px;
 border-radius: 25px;
}

.status_review {
 background-color: orange;
 color: #fff;
 padding-inline: 5px;
 border-radius: 25px;
}