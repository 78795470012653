.DataTableHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    height: 15%;
    padding-left: 5%;
    padding-right: 5%;
}
.DataTable {
    border-radius: 10px;
    display: flex;
    width: 100%;
    height: 100%;
    background-color: #fff;
    align-self: center;
}