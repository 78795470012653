.container {
    flex: 1;
    display: flex;
    flex-direction: row;
    overflow: hidden;
}

.bodyWithHeader {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.bodyContainer {
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
}

.body {
    padding: var(--body-padding);
    width: calc(100% - var(--body-padding) * 2);
    height: max-content;
    position: relative;
}

.bodyFullHeight {
    height: calc(100% - var(--body-padding) * 2);
}

.loadspinner {
  justify-content: center;
  border: 8px solid #64C4B6;
  border-top: 8px solid #0b3758;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1.5s linear infinite;
}

.loadingsection {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 100000;
  position: absolute;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
