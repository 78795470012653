.main {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.BodySection {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
}

.DataTableHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  height: var(--body-header-height);
  padding-left: 5%;
  padding-right: 5%;
}

.DropdownFilters {
  width: 30%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.Dropdown {
  width: 30%;
  height: 33%;
  margin-right: 2%;
  border-radius: 8px;

}

.DataTable {
  border-radius: 10px;
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #fff;
  align-self: center;
}

.usercard {
  display: flex;
  height: fit-content;
  width: 240px;
  flex-direction: column;
  justify-content: left;
  padding: 15px;
  position: absolute;
  z-index: 100000;
  background-color: rgb(255, 255, 255);
  border-radius: 12px;
  border: #000 0.5px solid;
  box-shadow: 5px 5px #88888826;
}

.UserDetails_Data_Title {
  font-size: small;
  height: 25px;
  color: #808080;
}

.UserDetails_Data_Result {
  font-size: small;
  height: 25px;
  color: #000;
  margin-right: 5%;
}

.UserDetails_Data_Row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.from_to_span {
  color: #64c4b6;
  cursor: pointer;
}