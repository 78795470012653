.maind {
  display: flex;
  width: 100%;
  flex-direction: column;
  overflow: hidden;
}

.BodySectiond {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
}

.DataTableHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  height: var(--body-header-height);
  padding-left: 5%;
  padding-right: 5%;
}

.DataSection {
  display: flex;
  width: 100%;
  height: 100%;
  gap: 10px;
}

.TicketData {
  width: 30%;
  height: 400px;
  border-radius: 10px;
  background-color: #FFF;
}

.ButtonsSections {
  width: 50%;
  height: 50%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.TicketHistorySection {
  width: 70%;
  height: 100%;
  border-radius: 10px;
  background-color: rgb(217, 217, 217);
}

.scrollhistory {
  width: 100%;
  height: 100%;
}

.Details_title {
  display: flex;
  font-size: medium;
  width: 90%;
  margin-left: 5%;
  border-bottom: 1px solid #80808049;
}

.UserDetails_Data {
  display: flex;
  height: auto;
  flex-direction: column;
  justify-content: left;
  margin-left: 5%;
  margin-top: 5%;
}

.TicketDetails_Data_Title {
  font-size: small;
  height: 40px;
  color: #808080;
}

.TicketDetails_Data_Result {
  font-size: small;
  height: 40px;
  color: #000;
  margin-right: 5%;
}

.UserDetails_Data_Row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Dropdown {
  width: 30%;
  height: 33%;
  margin-right: 2%;
  border-radius: 8px;

}

.grid {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.historytext {
  opacity: 0.6;
}